import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import {
  Tab,
  Tooltip,
  initTE,
} from "tw-elements";
import Swiper from "swiper";
import { Navigation, Pagination, Autoplay,FreeMode } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
Alpine.plugin([mask]);
initTE({ Tab,Tooltip });
(function ($) {
  "use strict";
  window.HAMTA = {};

  /*====== Preloader ======*/
  window.agent = $("body").data("agent");
  const isMobile = checkMobile();
  document.addEventListener('livewire:initialized', () => {
    Livewire.on('clearFilter', (event) => {
        let skipSlider = document.querySelector(".styles_noUi-horizontal__dw6Te");
        let $sliderFrom = document.querySelector(".TextField_TextField__input__hFMFl[name=min]");
        let $sliderTo = document.querySelector(".TextField_TextField__input__hFMFl[name=max]");
        $sliderFrom.value = 0;
        $sliderTo.value = $sliderTo.dataset.range;
        skipSlider.noUiSlider.set([$sliderFrom.dataset.range, $sliderTo.dataset.range])
    });
    const prefix = 'pages.';
    const isPage = (name) =>[prefix + 'product-category',prefix + 'product-offer',prefix + 'product-tag','modals.products'].includes(name);
    Livewire.hook('commit', ({
        component,
        commit,
        respond,
        succeed,
        fail
    }) => {
        if (isPage(component.name)) {
          window.dispatchEvent(new CustomEvent('loading', {
            detail: {
                loading: true
            }
        }));
        }
  
        respond(() => {
            window.dispatchEvent(new CustomEvent('loading', {
                detail: {
                    loading: false
                }
            }));
            if(isPage(component.name)) HAMTA.SwiperSlider();
        })
    })
    document.addEventListener('init-component', () => {
      HAMTA.Tooltip();
  })
    Livewire.on("alert", (event) => {
      if($(".modal.show").length)$(".modal.show").modal('hide');
      Swal.fire({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
        allowOutsideClick: true,
        icon: event.type,
        title: event.msg,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    });
    Livewire.on("urlChanged", (event) => {
      history.pushState(null, null, event.url);
      const meta = event.meta;
      if (meta) {
        $("title").html(meta.title);
        $("meta[name=description]").attr("content", meta.description);
        $('meta[property="og:title"]').attr("content", meta.title);
        $('meta[property="og:description"]').attr("content", meta.description);
        $('meta[name="twitter:title"]').attr("content", meta.title);
        $('meta[name="twitter:description"]').attr("content", meta.description);
      }
    });
    Livewire.on("modal", (event) => {
      $("#" + event.target).modal(event.status || 'show');
      setTimeout(() => HAMTA.Select2(), 100);
    });
    Livewire.on("changed", (event) => {
      if (event.plugin == "select2")
        setTimeout(() => HAMTA.Select2(), 100);
    });
  })
  
  window.onresize = function(){ 
    if(isMobile != checkMobile())location.reload();
   }
  document.addEventListener("livewire:navigating", () => {
    window.dispatchEvent(new CustomEvent("unlock-scroll"));
  });
  document.addEventListener("livewire:navigated", () => {
    $('.tooltip-inner').parent().remove();
    $(".back-to-top").fadeOut();
    $(".preloader").fadeOut(function () {
      $(this).remove();
    });
    $("footer").css("padding-bottom", $(".box-price-info").outerHeight());
    if (agent == "desktop")$(".page-wrapper").css("padding-top", $(".page-header").height());
      HAMTA.StickySidebar();
      HAMTA.SwiperSlider();
      HAMTA.NavigateLink();
      HAMTA.Popup();
      HAMTA.Countdown();
      HAMTA.Readmore();
      HAMTA.Select2();
      HAMTA.Tooltip();
      HAMTA.Modal();
      HAMTA.Tab();
  });

  function checkMobile(){
    return 'serviceWorker' in navigator && /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  /*====== end Preloader ======*/

  HAMTA.EasyNumberSeparator = function(){
    if($('input.currency').length) easyNumberSeparator();
  }

  HAMTA.Tooltip = function(){
    document.querySelectorAll('[data-te-toggle=tooltip]').forEach(elm => Tooltip.getOrCreateInstance(elm))
  }

  HAMTA.Tab = function(){
    document.querySelectorAll('[data-te-toggle=pill]').forEach(elm => {
      const tab = new Tab(elm);
      elm.addEventListener('click', (e) => {
        e.preventDefault();
        tab.show();
      });
    });
  }

  HAMTA.Popup = function(){
    const popup = $("#popupModal");
if (popup.length) {
  setTimeout(() => popup.modal('show'), popup.data("delay"));
  popup.find(".romeo-embed-inner").click(function () {
    $(this).remove();
    document.querySelector("#popupModal video").play();
  });

  popup.on('modal-hide',function () {
    document.cookie =
      "popup_" + popup.data("page") + "_" + popup.data("id") + "=1";
    $(this).remove();
  });
}
  }

  HAMTA.NavigateLink = function(){
    document.querySelectorAll('.swiper-wrapper a[x-navigate]').forEach(tag => tag.addEventListener('click',e => {
      e.preventDefault();
        window.dispatchEvent(new CustomEvent("hide-search"));
      Livewire.navigate(tag.href)
    }));
  }

  /*====== StickyHeader ======*/
  HAMTA.StickyHeader = function () {
   
    let t = $(".back-to-top");
    if ($(".box-price-info").length)
      t.css("bottom", $(".box-price-info").height() + 16);
    let lastScrollTop = 0;
    const agent = $("body").data("agent");
    $(window).scroll(function () {
      const header = $(".page-header");
      const scrollTop = $(window).scrollTop();
      scrollTop > 1e3 ? t.fadeIn(500) : t.fadeOut(500);
      if (agent == "desktop") {
        if (scrollTop > header.height() && scrollTop > lastScrollTop){
          header.find(".nav-content").addClass("hidden--nav-wrapper");
          header.css('height',header.find('.header-content').height())
      }else {
          header.find(".nav-content").removeClass("hidden--nav-wrapper");
          header.css('height',header.find('.header-content').height() + header.find(".nav-content").height())
        }
        $(".styles_Tabs__XG9uc").css(
          "top",
          $(".header-content").outerHeight() +
            (header.find(".nav-content:not(.hidden--nav-wrapper)").height() ||
              0) -
            1
        );
      }

      lastScrollTop = scrollTop;
      if (header.css("display") == "block") {
        $(".bottom-page-header .has-children.active").css(
          "top",
          $(".page-header").outerHeight()
        );
      }
    });
  };

  HAMTA.Readmore = function () {
    const read = $(".meta-desc");
    if (read.length)
      read.readmore({
        collapsedHeight: 148,
        moreLink:
          '<a href="#" class="justify-center highlight absolute text-secondary-500 bottom-0 right-0 flex w-full py-3 font-semibold">ادامه مطلب</a>',
        lessLink:
          '<a href="#" class="justify-center text-secondary-500 flex w-full py-3 font-semibold">بستن</a>',
      });
  };
  /*====== end StickyHeader ======*/

  /*====== CategoryList ======*/
  (HAMTA.CategoryList = function () {
    var t;
    $(document).on(
      {
        mouseenter: function () {
          window.dispatchEvent(new CustomEvent("lock-scroll"));
        },
        mouseleave: function () {
          window.dispatchEvent(new CustomEvent("unlock-scroll"));
        },
      },
      ".nav-wrapper > ul > .category-list"
    );
    function setSize(selector) {
      const t = $(".bottom-page-header");
      const i = $(window).width() - (t.offset().left + t.outerWidth());
      const s = selector.parents(".offset-bar");
      const a = s.offset().left;
      selector.find(".wrapper").css({
        width: a - i,
        minHeight: s.height(),
        right: $(window).width() - a - 1,
        top: $(".page-header").outerHeight(),
      });
    }
    // setSize($(".category-list ul:eq(1)>li:eq(0)"));
    // $(".category-list ul:eq(1)>li:eq(0)").addClass("active");
    $(document).on('mouseenter','.page-header ul > .category-list .has-children',function(){setSize($(this))});
    $(document).on(
      {
        mouseenter: function () {
          $(this).hasClass("menu-item-has-mega-menu") ||
            (t && clearTimeout(t),
            $(this).addClass("active").siblings().removeClass("active"));
        },
        mouseleave: function () {
          if (!$(this).hasClass("menu-item-has-mega-menu")) {
            var i = $(this);
            t = setTimeout(function () {
              return i.removeClass("active");
            }, 500);
          }
        },
      },
      ".menu-item-has-children.menu-item-has-mega-menu,.menu-item-has-children>.offset-bar:not(.xg-mega-menu-container)>ul>li,.menu-item-has-children:not(.category-list)>ul>li>ul>li"
    );
    $(document).on("mouseenter", ".search-category ul>li", function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  }),
    /*====== end CategoryList ======*/

    /*====== ResponsiveHeader ======*/
    (HAMTA.ResponsiveHeader = function () {
      $(document).on(
        "click",
        ".header-responsive .btn-toggle-side-navigation",
        function (event) {
          $(this).siblings(".side-navigation").addClass("toggle");
        }
      );
      $(document).on(
        "click",
        ".header-responsive .side-navigation ul > li.has-children",
        function (event) {
          if ($(event.target).is("li")) {
            event.preventDefault();
            event.stopPropagation();
            $(this).toggleClass("active");
            $(this).find("ul:first").slideToggle(100);
          }
        }
      );
      $(document).on(
        "click",
        ".header-responsive .btn-close-filter-sidebar",
        function (event) {
          $(".side-navigation").removeClass("toggle");
        }
      );
    });
  /*====== end ResponsiveHeader ======*/

  /*====== NiceScroll ======*/
  HAMTA.NiceScroll = function () {
    const scroll = $(".do-nice-scroll");
    if (scroll.length)
      $(".do-nice-scroll").niceScroll({
        railalign: "left",
      });
  };

  HAMTA.Countdown = function(){
    $('.countdown-offer').each(function(){
      const elm = $(this);
      let distance = Number(elm.data('end')) * 1000;
      const x = setInterval(function() {
          distance -= 1000;
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          let text = '';
          if(days > 0) text += (days < 10 ? '0' + days : days) + ":"
          text += (hours < 10 ? '0' + hours : hours) + ":" + (minutes < 10 ? '0' + minutes : minutes) + ":" + (seconds < 10 ? '0' + seconds : seconds);
          elm.find('span').html(text);
          if (distance < 0) {
              clearInterval(x);
              elm.html('00:00:00');
          }
      }, 1000);
  });
  }

  HAMTA.VideoModal = function(){
    $(document).on('#videoModal','modal-hide', function() {
      const video = document.querySelector('#videoModal video');
      video.pause();
      $('#videoModal').find('.embed_code').html('');;
  })

  $('.video-gallery .video-item').click(function() {
      const code = $(this).data('code');
      const videoModal = $('#videoModal');
      videoModal.find('.modal-title').html($(this).data('title'));
      if ($(this).data('method') == 'direct_link') {
          const video = document.querySelector('#videoModal video');
          video.setAttribute('poster', $(this).find('img').attr('src'));
          video.querySelector('source').setAttribute('src', code);
          video.load()
          videoModal.find('.direct_link').removeClass('d-none');
          videoModal.find('.embed_code').addClass('d-none');
      } else {
          videoModal.find('.direct_link').addClass('d-none');
          videoModal.find('.embed_code').html(code).removeClass('d-none');
      }
      videoModal.modal();
  });
  }
  /*====== end NiceScroll ======*/

  /*====== BackToTop ======*/
  HAMTA.BackToTop = function () {
    $(".back-to-top").click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        700
      );
      return false;
    });
  };
  HAMTA.Modal = function () {
    $.fn.modal = function(status = 'show') {
      if(status == 'show') {
        $('.GeneralOverlay_GeneralOverlay__ZT9dx').addClass('z-99');
        window.dispatchEvent(new CustomEvent("lock-scroll"));
        document.querySelector('#' + this.attr('id'))?.dispatchEvent(new CustomEvent("modal-show"));
        this.addClass('show');
      }else {
        $('.GeneralOverlay_GeneralOverlay__ZT9dx').removeClass('z-99');
        window.dispatchEvent(new CustomEvent("unlock-scroll"));
        document.querySelector('#' + this.attr('id'))?.dispatchEvent(new CustomEvent("modal-hide"));
        this.removeClass('show');
      }
  };
    $(document).on("click", "[data-toggle=modal]", function () {
      $($(this).data("target")).modal('show');
    });
    $(document).on("click", "[data-dismiss=modal]", function () {
      $(this).parents(".modal").modal('hide')
    });
    $(document).on("click", ".modal", function (e) {
      e.stopPropagation();
      if (!$(this).hasClass('static') && 
        !$(e.target).hasClass("modal-content") &&
        !$(e.target).parents(".modal-content").length
      ) {
        $(this).modal('hide');
      }
    });
  };
  /*====== end BackToTop ======*/

  /*====== end productAddTo ======*/

  /*====== Select2 ======*/
  HAMTA.Select2 = function () {
    let select2 = $(".select2");
    if (select2.length) {
      select2.select2({
       
      });
    }
  };
  /*====== end Select2 ======*/

  /*====== end CollapseWidget ======*/

  /*====== SwiperSlider ======*/
  HAMTA.SwiperSlider = function () {
    if ($(".default-slider").length) {
      let defaultSlider = new Swiper(".default-slider", {
        modules: [Navigation],
        navigation: {
          nextEl: ".styles_Slider__next-button-selector__R9M5X",
          prevEl: ".styles_Slider__prev-button-selector__fsha9",
        },
        slidesPerView: 5,
      });
    }
    if ($(".slider-single").length) {
      let sliderSingle = new Swiper(".slider-single", {
        modules: [Navigation, Pagination, Autoplay],
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets: true,
        },
        autoplay: {
          delay: 6000,
        },
        effect: "slide",
      });
      $(".slider-single").hover(
        function () {
          sliderSingle.autoplay.stop();
        },
        function () {
          sliderSingle.autoplay.start();
        }
      );
    }
    if ($(".slider-offer").length) {
      let sliderOffer = new Swiper(".slider-offer", {
        modules: [Navigation, FreeMode],
        speed: 800,
        spaceBetween: 6,
        freeMode: true,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".styles_Slider__next-button-selector__R9M5X",
          prevEl: ".styles_Slider__prev-button-selector__fsha9",
        },
      });
    }
    if ($(".slider-brand").length) {
      let sliderBrand = new Swiper(".slider-brand", {
        modules: [Navigation,FreeMode],
        speed: 800,
        spaceBetween: 0,
        freeMode: true,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".styles_Slider__next-button-selector__R9M5X",
          prevEl: ".styles_Slider__prev-button-selector__fsha9",
        },
      });
    }
    if ($(".slider-lg").length) {
      let sliderLg = new Swiper(".slider-lg", {
        modules: [Navigation, FreeMode],
        speed: 800,
        slidesPerView: "auto",
        freeMode: true,
        navigation: {
          nextEl: ".styles_Slider__next-button-selector__R9M5X",
          prevEl: ".styles_Slider__prev-button-selector__fsha9",
        }
      });
    }

    if ($(".slider-md").length) {
      let sliderMd = new Swiper(".slider-md", {
        modules: [Navigation, FreeMode],
        speed: 800,
        spaceBetween: 16,
        freeMode: true,
        navigation: {
          nextEl: ".styles_Slider__next-button-selector__R9M5X",
          prevEl: ".styles_Slider__prev-button-selector__fsha9",
        },
        breakpoints: {
          1200: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          576: {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1.6,
            spaceBetween: 8,
          },
          0: {
            slidesPerView: 1.3,
            spaceBetween: 8,
          },
        },
      });
    }
  };
  /*====== end SwiperSlider ======*/

  /* end Product +/- ======*/

  /*====== Filter price ======*/
  HAMTA.FilterPrice = function () {
    if ($(".product-list_ProductList__sidebar__N_suS").length) {
      let skipSlider = document.querySelector(".styles_noUi-horizontal__dw6Te");
      let $sliderFrom = document.querySelector(
        ".TextField_TextField__input__hFMFl[name=min]"
      );
      let $sliderTo = document.querySelector(
        ".TextField_TextField__input__hFMFl[name=max]"
      );
      $sliderFrom.addEventListener("change", (e) => {
        skipSlider.noUiSlider.set([
          e.target.value.replaceAll(",", ""),
          $sliderTo.value.replaceAll(",", ""),
        ]);
      });
      $sliderTo.addEventListener("change", (e) => {
        skipSlider.noUiSlider.set([
          $sliderFrom.value.replaceAll(",", ""),
          e.target.value.replaceAll(",", ""),
        ]);
      });
      let min = parseInt($sliderFrom.dataset.range),
        max = parseInt($sliderTo.dataset.range);
      noUiSlider.create(skipSlider, {
        start: [
          $sliderFrom.value.replaceAll(",", ""),
          $sliderTo.value.replaceAll(",", ""),
        ],
        connect: true,
        direction: "rtl",
        format: wNumb({
          thousand: ",",
          decimals: 0,
        }),
        step: 1,
        range: {
          min: min,
          max: max,
        },
        pips: {
          mode: "range",
          density: 20,
        },
      });
      let skipValues = [$sliderFrom, $sliderTo];
      skipSlider.noUiSlider.on("update", function (values, handle) {
        skipValues[handle].value = values[handle];
      });
    }
  };
  /* end Filter price ======*/

  /*====== sidebar-sticky ======*/
  HAMTA.StickySidebar = function () {
    let stickyHeader = $(".sticky-sidebar");
    if (stickyHeader.length) {
      stickyHeader.theiaStickySidebar({
        additionalMarginTop:
          $("header.page-header").height() +
          (stickyHeader.data("additional") || 0),
      });
    }
  };
  /* end sidebar-sticky ======*/


  /* end color switcher ======*/
  $(document).ready(function () {
      HAMTA.StickyHeader(),
      HAMTA.CategoryList(),
      HAMTA.ResponsiveHeader(),
      HAMTA.NiceScroll(),
      HAMTA.BackToTop();
  });
})(jQuery);
